import styled from 'styled-components'

import { theme } from 'styles/theme'

const { zIndices, colors, space, radii, shadows, media } = theme

export const CTA = styled.div<{ $hideTablet: boolean }>`
  position: fixed;
  z-index: ${zIndices[2]};
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: ${space['spacing-md']};
  border-radius: ${radii['corner-radius-md']} ${radii['corner-radius-md']} 0 0;
  background-color: ${colors['neutral-background-default']};
  text-align: center;
  box-shadow: ${shadows['shadow-level-4']} rgba(0 0 0 / 24%);
  display: flex;
  gap: 14px;
  flex-direction: column;

  button {
    width: 100%;
    justify-content: center;
  }

  @media (${media.tablet}) {
    ${({ $hideTablet }) =>
      $hideTablet &&
      `
      display: none;
    `}
  }

  @media (${media.desktop}) {
    display: none;
  }
`
