import styled from 'styled-components'

import { theme } from 'styles/theme'
const { colors, fontSizes, media, space } = theme

type BgType = 'default' | 'medium' | 'contrast' | 'contrast-medium'

const getSectionBgColor = (bg: BgType = 'default') => colors[`neutral-background-${bg}`]

export const Section = styled.section<{ $bg?: BgType }>`
  position: relative;
  width: 100%;
  padding-top: ${space['spacing-xxxl']};
  padding-bottom: ${space['spacing-xxxl']};
  background-color: ${({ $bg }) => getSectionBgColor($bg)};

  @media (${media.desktop}) {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
`

export const Infos = styled.div`
  br {
    display: none;
  }

  h2,
  > p {
    margin-bottom: ${space['spacing-md']};
  }

  .ds-button {
    width: 100%;
    justify-content: center;
  }

  @media (${media.tablet}) {
    .ds-button {
      width: 19rem;
      justify-content: space-between;
    }
  }

  @media (${media.desktop}) {
    br {
      display: block;
    }

    > p {
      font-size: ${fontSizes['font-size-body-1']};

      &.subtitle {
        margin-bottom: ${space['spacing-xl']};
      }
    }
    
    .disclaimer {
      font-size: ${fontSizes['font-size-body-2']};
    }

    h2,
    h2 span {
      font-size: ${fontSizes['font-size-heading-2']};
    }

    .ds-button {
      margin-top: ${space['spacing-xl']};
    }
  }
`
